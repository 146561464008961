/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    pre: "pre",
    code: "code",
    h3: "h3",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "The best way to get started is to try a simple example. Quill is initialized with a DOM element to contain the editor. The contents of that element will become the initial contents of Quill."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-html"
  }, "<!-- Include stylesheet -->\n<link href=\"https://cdn.jsdelivr.net/npm/quill@2.0.0-beta.0/dist/quill.snow.css\" rel=\"stylesheet\" />\n\n<!-- Create the editor container -->\n<div id=\"editor\">\n  <p>Hello World!</p>\n  <p>Some initial <strong>bold</strong> text</p>\n  <p><br /></p>\n</div>\n\n<!-- Include the Quill library -->\n<script src=\"https://cdn.jsdelivr.net/npm/quill@2.0.0-beta.0/dist/quill.js\"></script>\n\n<!-- Initialize Quill editor -->\n<script>\n  const quill = new Quill('#editor', {\n    theme: 'snow'\n  });\n</script>\n")), "\n", React.createElement(_components.p, null, "And that's all there is to it!"), "\n", React.createElement(_components.h3, null, "Next Steps"), "\n", React.createElement(_components.p, null, "The real magic of Quill comes in its flexibility and extensibility. You can get an idea of what is possible by playing around with the demos throughout this site or head straight to the ", React.createElement(_components.a, {
    href: "/playground/"
  }, "Interactive Playground"), ". For an in-depth walkthrough, take a look at ", React.createElement(_components.a, {
    href: "/guides/how-to-customize-quill/"
  }, "How to Customize Quill"), "."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
